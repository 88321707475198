import classes from './JoinUs.module.css';
import React, { useContext, useEffect, useState } from 'react';
import BgWithText from '../../components/BgWithText/BgWithText';
import PaypalLogo from '../../assets/paypal.png';
import { MEMBERSHIP_LEVELS, MEMBERSHIP_TEXT, SET_USER } from '../../constants';
import WhiteWall from '../../assets/whiteWall.png';
import VolunteerFooter from '../../components/VolunteerFooter/VolunteerFooter';
import { AuthContext } from '../../context/AuthContext';
import { API, ListSubscriptions, SubmitWithToken } from '../../utils/FormSubmitHandler';
import LoginModal from '../../components/LoginModal/LoginModal';
import Modal from 'react-responsive-modal';
import ViewCards from '../../components/ViewCards/ViewCards';
import { toast } from 'react-toastify';
import MembershipSummary from '../../components/MembershipSummary/MembershipSummary';
import moment from 'moment';
import { camelToSentence } from '../../utils/stringHelper';

const JoinUs = () => {
  const { state, dispatch } = useContext(AuthContext);
  const [subscriptions, setSubscriptions] = useState([]);
  const [loginModal, setLoginModal] = useState(false);
  const [cardsModal, setCardsModal] = useState(false);
  const [summaryModal, setSummaryModal] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState('');
  const [membershipId, setMembershipId] = useState('');
  const [coupon, setCoupon] = useState('');
  const [disabled, setDisabled] = useState('');

  useEffect(() => {
    ListSubscriptions()
      .then(({ data }) => {
        setSubscriptions(data.data);
      })
      .catch((err) => console.log(err));
  }, []);

  const handleSubscribe = (membership) => {
    if (!(state && state.isAuthorized)) setLoginModal({ show: true, isLogin: true });
    else if (state.data.membershipStatus !== 'inactive')
      setLoginModal({
        show: true,
        text: `You are already subscribed to the Harvard Club of Silicon Valley.`,
      });
    else if (membership.nickname === 'recentGraduate' && state.data.class === 0) {
      setLoginModal({
        show: true,
        text: `Recent graduate cannot be subscribed for class N/A. Please update your class in Profile Section`,
      });
    } else if (membership.nickname === 'recentGraduate' && state.data.class <= moment().format('YYYY') - 6) {
      setLoginModal({
        show: true,
        text: `Recent graduates are Harvard alumni who graduated in the last 5 years [till ${
          moment().format('YYYY') - 5
        }]. Please select another membership type as your graduating year has exceeded this`,
      });
    } else {
      setMembershipId(membership);
      setSummaryModal(true);
    }
  };

  const handleSummaryCheck = (coupon) => {
    console.log('c');
    setCoupon(coupon);
    setCardsModal(true);
  };

  const handleConfirmSubscription = () => {
    setDisabled(true);
    SubmitWithToken(
      {
        customer: state.data.customerId,
        paymentMethod,
      },
      API.SET_DEFAULT_PAYMENT
    )
      .then(({ data }) => {
        if (data.success) {
          handleActivateSubscription();
        }
      })
      .catch((err) => {
        setDisabled(false);
        console.log(err);
      });
  };

  const handleActivateSubscription = () => {
    const cancelAtPeriodEnd = membershipId.nickname === 'recentGraduate';
    SubmitWithToken(
      {
        customer: state.data.customerId,
        price: membershipId.id,
        coupon,
        cancelAtPeriodEnd,
      },
      API.SUBSCRIBE
    )
      .then(({ data }) => {
        toast.success('Subscription Success!');
        const payload = { ...state };
        payload.membershipStatus = membershipId.nickname;
        payload.data.membershipStatus = membershipId.nickname;
        dispatch({ type: SET_USER, payload });
        setSummaryModal(false);
        setCardsModal(false);
        setDisabled(false);
      })
      .catch((err) => {
        setDisabled(false);
        console.log(err);
      });
  };

  return (
    <div>
      <BgWithText title='Join' image='bg2' />
      <div className={classes.Content}>
        <div className={classes.HeadContainer}>
          <div className={classes.JoinUsContainer}>
            <div className='LargeText'>
              Join <span className='brown'>Us</span>
            </div>
            <div className={classes.SubFont + ' medium'}>
              <span className='brown'>Join</span> or <span className='brown'>Renew</span> your membership. Get discounts on event
              registrations, and receive alerts on club events and announcements.
            </div>
          </div>
          {/* <div className={classes.PaypalCard}>
            <div className={classes.PaypalTitle}>
              <span className='brown'>PayPal™:</span>Pay Your Membership Dues Here
            </div>
            <div className={classes.SubText + ' medium'}>
              <div>
                <span className='brown'>Go here To pay by PayPal™,</span> and fill in the appropriate form fields to make your payment.
              </div>
              <div>
                <img className={classes.PaypalLogo} src={PaypalLogo} alt='' />
              </div>
            </div>
          </div> */}
        </div>
        <div className={classes.MembershipContainer}>
          <div className='LargeText'>
            Membership <span className='brown'>Levels</span>
          </div>
          <p className='medium small-font'>(All contributions to HCSV are tax-deductible)</p>
          <div className={classes.MembershipCardContainer}>
            {subscriptions.map((membership) =>
              membership.active ? (
                <div className={classes.Card} onClick={() => handleSubscribe(membership)}>
                  <div className={classes.CardContent}>
                    <div className={classes.CardTitle}>
                      <div className={classes.BigTitle}>$ {parseInt(membership.unit_amount) / 100}</div>
                    </div>
                    <div className={classes.SubTitle}>
                      <p>{camelToSentence(membership.nickname)}</p>
                    </div>
                    {membership.nickname && membership.nickname.toLowerCase() && (
                      <div className={classes.CardTextContent}>
                        <p>{MEMBERSHIP_TEXT[membership.nickname.toLowerCase()]}</p>
                      </div>
                    )}
                  </div>
                </div>
              ) : null
            )}
          </div>
        </div>
        <div className={classes.MembershipText}>
          <p>
            Premium membership has many privileges! Patron and Sponsor Members receive invitations to exclusive events and qualify for
            additional benefits and prizes. In addition, Patron and Sponsor Members may receive preferential seating at events. These
            memberships pay for themselves after attending as few as two HCSV events.
          </p>
        </div>
        <div className={classes.Eligibility}>
          <div className='LargeText'>
            Membership <span className='brown'>Eligibility</span>
          </div>
          <p className='medium small-font'>
            Anyone who has been associated with any department at Harvard University for at least one academic term, as a student or
            instructor is eligible for membership. In addition, parents of undergraduates are encouraged to join both during and after their
            child’s enrollment.
          </p>
        </div>
        <div className={classes.BorderContainer}>
          <div className='border'></div>
        </div>
        <div>
          <div className='LargeText'>
            Additional <span className='brown'>Contribution Opportunities</span>
          </div>
          <p className='medium small-font'>In addition to dues, the HCSV encourages you to support important Club initiatives:</p>
          <div className={classes.BookAwardFund}>
            <div>
              <div className={classes.BookTitle}>Book Award Fund</div>
              <p>
                Harvard Book Awards are awarded to outstanding students in their junior year of high school. The winners are selected by
                each participating high school based on academic excellence, exceptional personal qualities and significant contribution to
                their school or community.
              </p>
              <p>
                Each Harvard Book Award costs $30. Your generous donation will allow the Harvard Club of Silicon Valley to expand the
                Harvard Prize Book program to more of the seventy plus high schools in Santa Clara County. Please donate now!
              </p>
              <p>
                At the annual awards ceremony of the participating schools, each winner is presented with a book that is inscribed to the
                recipient
              </p>
            </div>
            <div className={classes.ImageContainer}>
              <img className={classes.WhiteWall} src={WhiteWall} alt='' />
              <div className={classes.OverlayText}>
                The Harvard <br /> Book
              </div>
            </div>
          </div>
        </div>
        <div className={classes.Footer}>
          <div className={classes.FooterTitle}>Donate to the Harvard Club of Silicon Valley Harvard Prize Book Program</div>
          <VolunteerFooter />
        </div>
        <div className={classes.BorderContainer}>
          <div className='border'></div>
        </div>
        <div className={classes.PaymentMethod}>
          <div className='LargeText'>
            <span className='brown'>Payment </span>Methods
          </div>
          <p>
            Dues and donations can be paid using PayPal™. Dues payments by cash or check are also accepted at any HCSV event. Please reach
            out our treasurer Shaun Chaudhuri at{' '}
            <a
              target='_blank'
              rel='noreferrer'
              href='mailto:sdchaudhuri2393@gmail.com'
              style={{ cursor: 'pointer' }}
              className='brown underline'
            >
              sdchaudhuri2393@gmail.com.
            </a>
            to discuss those payment options.
          </p>
        </div>
      </div>
      <LoginModal setOpen={setLoginModal} open={loginModal.show} {...loginModal} />
      <div className='events-modal-container'>
        <Modal open={cardsModal} setModalOpen={setCardsModal} classNames={[classes.MyModal]} onClose={() => setCardsModal(false)}>
          <div className={classes.ModalContent}>
            <ViewCards setPaymentMethod={setPaymentMethod} />
            <button disabled={disabled} className='btn btn-primary' style={{ marginLeft: 'auto' }} onClick={handleConfirmSubscription}>
              Confirm Subscription
            </button>
          </div>
        </Modal>
      </div>
      <div className='events-modal-container'>
        <Modal open={summaryModal} setModalOpen={setSummaryModal} classNames={[classes.MyModal]} onClose={() => setSummaryModal(false)}>
          <div className={classes.SummaryModalContent}>
            <MembershipSummary
              nickname={membershipId.nickname}
              amount={parseInt(membershipId.unit_amount) / 100}
              handleSummaryCheck={handleSummaryCheck}
            />
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default JoinUs;
