import classes from './BookPrize.module.css';
import React from 'react';
import BgWithText from '../../components/BgWithText/BgWithText';
import GraduateImage from '../../assets/grad.png';
import AccordionComponent from '../../components/Accordion/Accordion';
import { WINNERS_HISTORY, WINNERS_LIST } from '../../constants';
import VolunteerFooter from '../../components/VolunteerFooter/VolunteerFooter';
import InfoCard from '../../components/InfoCard/InfoCard';

const BookPrize = () => {
  return (
    <div className={classes.BookPrize}>
      <BgWithText title='Book Prize' image='bg2' />
      <div className={classes.Content}>
        <div className={classes.Container}>
          <div className={classes.ContainerHead}>
            <div style={{ flexDirection: 'column', display: 'flex' }}>
              <p>
                The Harvard Book Prize is awarded to an outstanding Junior year student selected by the faculty of each participating high
                school, based on academic excellence, exceptional personal qualities and significant contribution to school or community.
                Each winner receives a personally inscribed copy of “The Harvard Book”, an anthology of essays written by Harvard alumni
                spanning three centuries.
              </p>
              <p>
                Since 1910, the Harvard Book Prize has been an important way for Harvard Club members to help attract talented young people
                to Harvard and the opportunities in Cambridge. Harvard Book Prize books are presented annually in more than 2,000 high
                schools around the world, and are made available through generous donations from individual Harvard Club members.
              </p>
            </div>
            <div className={classes.ImageContainer}>
              <img className={classes.GraduateImage} src={GraduateImage} alt='' />
            </div>
          </div>
          <p>
            Through the Harvard Book Prize program, we at the Harvard Club of Silicon Valley hope to raise the visibility of Harvard among
            local secondary schools, and to encourage outstanding students in our area to consider applying to Harvard. We especially thank
            our members and friends who have generously contributed, and appreciate your continued support through future donations.
          </p>
        </div>
        <div className={classes.WinnersContainer}>
          <div className='LargeText'>
            <span className='brown'>2020 </span>Winners
          </div>
          <div className={classes.CardsContainer}>
            {WINNERS_LIST.map((item) => (
              <InfoCard image={item.image} title={item.name} subTitle={item.school} />
            ))}
          </div>
          <div className={classes.AccContainer}>
            {WINNERS_HISTORY.map((history) => (
              <AccordionComponent name={history.year} values={history.content} />
            ))}
          </div>
        </div>
        <div className={classes.Footer}>
          <VolunteerFooter />
        </div>
      </div>
    </div>
  );
};

export default BookPrize;
