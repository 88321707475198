import classes from './AboutUs.module.css';
import React from 'react';
import BgWithText from '../../components/BgWithText/BgWithText';
import AboutUs1 from '../../assets/aboutUs.png';
import AboutUs2 from '../../assets/aboutUs2.png';
import { BOARD_OF_DIRECTORS } from '../../constants';
import InfoCard from '../../components/InfoCard/InfoCard';
import FooterLogo from '../../assets/harvardShield.png';
const AboutUs = () => {
  return (
    <div>
      <BgWithText title='About Us' image='bg2' />
      <div className={classes.Content}>
        <div className={classes.Container}>
          <div style={{ justifyContent: 'space-between', display: 'flex' }}>
            <div
              style={{
                flexDirection: 'column',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <div className={classes.TextContainer} style={{ flexDirection: 'column', display: 'flex' }}>
                <p>
                  The Harvard Club of Silicon Valley organizes professional, social, and community service events for 15,000+ Harvard alumni
                  in the Silicon Valley area.
                </p>
                <p>
                  Anyone who has been associated with any department at Harvard University for at least one academic term, as a student or
                  instructor is eligible for membership. In addition, parents of undergraduates are encouraged to join both during and after
                  their child’s enrollment.
                </p>
              </div>
            </div>
            <div>
              <img className={classes.GraduateImage} src={AboutUs1} alt='' />
            </div>
          </div>
          <div style={{ justifyContent: 'space-between', display: 'flex' }}>
            <div style={{ alignItems: 'center', display: 'flex' }}>
              <img className={classes.GraduateImage} src={AboutUs2} alt='' />
            </div>
            <div
              style={{
                flexDirection: 'column',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <div style={{ flexDirection: 'column', display: 'flex' }}>
                <div className='LargeText bold'>
                  <span className='brown'>Our Mission</span> {'&'} Goals
                </div>
                <p>
                  The purpose of the Harvard Club of Silicon Valley is to foster a spirit of fraternity among graduates and former students
                  of Harvard University; serve in extending knowledge of the aims and achievements of Harvard University; promote the
                  extracurricular interests of all alumni and alumnae of Harvard; represent the interests in the Silicon Valley region;
                </p>
                <p>
                  Solicit donations for Harvard scholarships and Harvard Book Prize awards; solicit members for admissions volunteering;
                  recruit candidates for Harvard admission;
                </p>
                <p>
                  Encourage educational and recreational activities for its members, and generally do all things to promote and encourage
                  communication among Harvard University and its alumni/ae resident in the Silicon Valley.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.LeadersContainer}>
          <div>
            <div className='LargeText'>
              <span className='brown'>Leader</span>ship
            </div>
            <p className={classes.SubText}>Board of Directors</p>
          </div>
          <div className='border'></div>
          <div className={classes.CardsContainer}>
            {BOARD_OF_DIRECTORS.map((item) => (
              <InfoCard image={item.image} title={item.name} subTitle={item.position} />
            ))}
          </div>
        </div>
        <div className={classes.Footer}>
          <div className={classes.FooterCard}>
            <div className={classes.FooterOverlay}>
              <img src={FooterLogo} alt='' />
            </div>
            <div className={classes.FooterTextContainer}>
              <div>For questions about volunteering or events, contact :</div>
              <div className='semi-bold' style={{ marginTop: '0.6vw' }}>
                Niki Santo, santo@post.harvard.edu
              </div>
            </div>
          </div>
          <div className={classes.OverlayContainer}>
            <div className={classes.OverlayText}>Harvard Club Of Silicon Valley</div>
            <div className={classes.ThankyouText}>
              <span className='brown'>Harvard Club </span>Of Silicon Valley
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
