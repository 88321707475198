import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { animateScroll as scroll } from 'react-scroll';
import SimpleReactCalendar from 'simple-react-calendar';
import Day from 'simple-react-calendar/lib/RenderPropsComponents/Day/Day';

import UlArrow from '../../assets/rightArrow.png';
import ScrollTopLogo from '../../assets/scrollTop.png';
import { getAllEvents } from '../../utils/FormSubmitHandler';
import classes from './Footer.module.css';
import { get as lodashGet } from 'lodash';
import moment from 'moment';
import { PATHS } from '../../constants';

const Footer = () => {
  const [eventDates, setEventDates] = useState([]);
  const history = useHistory();

  useEffect(() => {
    getEventData();
  }, []);

  const getEventData = async () => {
    const { data } = await getAllEvents();
    const events = lodashGet(data, 'data.data') || [];
    const eventDates = events.map((event) => (!event.isCancelled ? moment(event.startDate).format('YYYY-MM-DD') : null));
    const filteredDates = eventDates ? eventDates.filter(Boolean) : [];
    setEventDates(filteredDates);
  };
  const ulImage = <img src={UlArrow} alt='' />;
  return (
    <div className={classes.Footer}>
      <div className={classes.FooterContainer}>
        <div className={classes.FooterDiv}>
          <div className={classes.FooterHeading}>Harvard Links</div>
          <div>
            <ul>
              <li>
                {ulImage}
                <a target='_blank' rel='noreferrer' href='https://www.linkedin.com/company/harvard-club-of-silicon-valley'>
                  Update Your Records @ Harvard
                </a>
              </li>
              <li>
                {ulImage}
                <a target='_blank' rel='noreferrer' href='https://alumni.harvard.edu/community'>
                  Harvard Alumni Directory
                </a>
              </li>
              <li>
                {ulImage}
                <a target='_blank' rel='noreferrer' href='https://harvardmagazine.com/'>
                  Harvard Magazine
                </a>
              </li>
              <li>
                {ulImage}
                <a target='_blank' rel='noreferrer' href='https://alumni.harvard.edu/community'>
                  {' '}
                  Harvard Alumni Association (HAA)
                </a>
              </li>
              <li>
                {ulImage}
                <a target='_blank' rel='noreferrer' href='https://www.harvard.edu/'>
                  Harvard University
                </a>
              </li>
              <li>
                {ulImage}
                <a target='_blank' rel='noreferrer' href='https://www.thecrimson.com/'>
                  The Crimson
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className={classes.FooterDiv}>
          <div className={classes.FooterHeading}>Events Calendar</div>
          <div className={classes.CalendarContainer}>
            <SimpleReactCalendar
              weekStartsOn={0}
              activeMonth={new Date()}
              renderDay={(props) => {
                const hasEvent = eventDates.includes(props.ISODate);
                return (
                  <Day
                    {...props}
                    isToday={hasEvent}
                    isSelectable={hasEvent}
                    handleOnClick={() => (hasEvent ? history.push('/events') : null)}
                  />
                );
              }}
            />
          </div>
        </div>
        <div className={classes.FooterDiv + ' ' + classes.SocialIcons}>
          <div className={classes.FooterHeading}>Social Media</div>
          <div className={classes.SocialIconsContainer}>
            <a target='_blank' rel='noreferrer' href='https://www.facebook.com/harvardclubsv/'>
              <div className={classes.SocialIcon}>
                <i class='fa fa-facebook' aria-hidden='true'></i>
              </div>
            </a>
            <a target='_blank' rel='noreferrer' href='https://www.linkedin.com/company/harvard-club-of-silicon-valley'>
              <div className={classes.SocialIcon}>
                <i class='fa fa-linkedin' aria-hidden='true'></i>
              </div>
            </a>
          </div>
        </div>
        <div className={classes.FooterDiv}>
          <div className={classes.FooterHeading}>
            <span>&nbsp;</span>
          </div>
          <div className={classes.SocialIconsContainer}>
            <img src={ScrollTopLogo} onClick={() => scroll.scrollToTop()} alt='' />
          </div>
        </div>
      </div>
      <div className={classes.Copyrights}>
        Copyright © 2021 HCSV - All rights reserved. |{' '}
        <span className={classes.Strong} onClick={() => history.push(PATHS.PRIVACY_POLICY)}>
          Privacy Policy
        </span>
      </div>
    </div>
  );
};

export default Footer;
