import classes from './Profile.module.css';
import React, { useContext, useEffect, useState } from 'react';
import BgWithText from '../../components/BgWithText/BgWithText';
import { ClassDropdown, DropdownComponent, Input, PhoneInput, TextArea } from '../../components/Inputs/Input';
import { Form, Formik } from 'formik';
import { AuthContext } from '../../context/AuthContext';
import ViewCards from '../../components/ViewCards/ViewCards';
import { cancelSubscription, getMySubscriptions, UpdateUser } from '../../utils/FormSubmitHandler';
import { toast } from 'react-toastify';
import { SET_USER, TITLE, SUFFIX, CLASSES, AFFILIATION } from '../../constants';
import { ProfileValidation } from '../../validations/ProfileValidation';
import TransactionsTable from '../../components/TransactionsTable/TransactionsTable';
import { camelToSentence } from '../../utils/stringHelper';
import { find as LodashFind } from 'lodash';
import moment from 'moment';
import Modal from 'react-responsive-modal';
import ChangeDefaultPayment from '../../components/ChangeDefaultPayment/ChangeDefaultPayment';

const Profile = () => {
  const { state, dispatch } = useContext(AuthContext);
  const [disabled, setDisabled] = useState(false);
  const [cardsModal, setCardsModal] = useState(false);
  const [subscription, setSubscription] = useState([]);
  const [detectChange, setDetectChange] = useState([]);

  useEffect(() => {
    if (state.data && state.data.customerId) {
      fetchSubscriptions();
    }
  }, []);

  const fetchSubscriptions = async () => {
    const { data } = await getMySubscriptions(state.data.customerId);
    const mySubscription = LodashFind(data.data, { plan: { nickname: state.membershipStatus, active: true } });
    setSubscription(mySubscription);
  };

  const handleSubmit = (values, { setSubmitting }) => {
    setDisabled(true);
    let processedValues = { ...values };
    if (processedValues.class === '0') processedValues.class = 0;
    delete processedValues['password'];

    UpdateUser(processedValues)
      .then(({ data }) => {
        if (data.success) {
          toast.success(data.data);
          const payload = { data: processedValues };
          payload.data.customerId = state.data.customerId;
          payload.membershipStatus = state.membershipStatus;
          dispatch({ type: SET_USER, payload });
          setDisabled(false);
        }
      })
      .catch((err) => {
        setDisabled(false);
      });
  };

  const handleCancelMembership = async () => {
    cancelSubscription(subscription.id)
      .then(({ data }) => {
        toast.success('Subscription cancelled successfully!');
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className=' profile'>
      <BgWithText title='Your Profile' image='bg2' />
      <div className={classes.Profile}>
        <div className={classes.Content}>
          <div className={classes.FormContent}>
            <div className='LargeText'>
              <div>
                <span className='brown'>Profile </span>Details
              </div>
            </div>
            <div className='border'></div>
            <p className='title-text-small brown' style={{ margin: '2em auto' }}>
              User Information
            </p>
            <Formik enableReinitialize validationSchema={ProfileValidation} initialValues={state.data || {}} onSubmit={handleSubmit}>
              {(props) => (
                <Form>
                  <div className={classes.InputContainer}>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                      className={classes.MobileInputs}
                    >
                      <Input name='firstName' label='First Name' required />
                      <Input name='middleName' label='Middle Name' />
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                      className={classes.MobileInputs}
                    >
                      <Input name='lastName' label='Last Name' required />
                      <Input name='lastNameAsStudent' label='Last Name As Student' />
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                      className={classes.MobileInputs}
                    >
                      <div>
                        <DropdownComponent
                          name='title'
                          label='Title'
                          values={TITLE}
                          setFieldValue={props.setFieldValue}
                          setFieldTouched={props.setFieldTouched}
                          getFieldMeta={props.getFieldMeta}
                          defaultValue={props.values.title}
                        />
                      </div>
                      <div>
                        <DropdownComponent
                          name='suffix'
                          label='Suffix'
                          values={SUFFIX}
                          setFieldValue={props.setFieldValue}
                          setFieldTouched={props.setFieldTouched}
                          getFieldMeta={props.getFieldMeta}
                          defaultValue={props.values.suffix}
                        />
                      </div>
                    </div>
                  </div>
                  <p className='title-text-small brown' style={{ margin: '2em auto' }}>
                    Contact Information
                  </p>
                  <div className={classes.InputContainer}>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                      className={classes.MobileInputs}
                    >
                      <Input name='email' label='Email' required></Input>
                      <PhoneInput name='phone' label='Phone Number'></PhoneInput>
                    </div>
                  </div>
                  <p className='title-text-small brown' style={{ margin: '2em auto' }}>
                    About Yourself
                  </p>
                  <div className={classes.InputContainer}>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                      className={classes.MobileInputs}
                    >
                      <ClassDropdown
                        values={CLASSES}
                        name='class'
                        label='class'
                        required
                        setFieldValue={props.setFieldValue}
                        setFieldTouched={props.setFieldTouched}
                        getFieldMeta={props.getFieldMeta}
                        defaultValue={props.values.class}
                      />
                      <DropdownComponent
                        values={AFFILIATION}
                        name='affiliation'
                        label='affiliation'
                        setFieldValue={props.setFieldValue}
                        setFieldTouched={props.setFieldTouched}
                        getFieldMeta={props.getFieldMeta}
                        defaultValue={props.values.affiliation}
                      />
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                      className={classes.MobileInputs}
                    >
                      <div>
                        <TextArea label='Address' name='addressLine1' defaultValue={props.values.addressLine1} />
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'space-around',
                        }}
                      >
                        <Input name='city' label='City' />
                        <Input name='state' label='State' />
                      </div>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                      className={classes.MobileInputs}
                    >
                      <PhoneInput name='phone' label='Phone Number' />
                      <Input name='zipcode' label='zipcode' />
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                      className={classes.MobileInputs}
                    >
                      <Input name='occupation' label='occupation' />
                      <Input name='company' label='company' />
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                      className={classes.MobileInputs}
                    >
                      <PhoneInput name='homePhone' label='Home Phone' />
                      <PhoneInput name='workPhone' label='Work Phone' />
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                      className={classes.MobileInputs}
                    >
                      <Input name='postEmail' label='Post Email' />
                      <PhoneInput name='fax' label='fax' />
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                      className={classes.MobileInputs}
                    >
                      <Input name='undergradDegreeAndYear' label='Undergraduate Degree and Year' />
                      <Input name='gradDegreeAndYear' label='Graduate Degree and Year' />
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                      className={classes.MobileInputs}
                    >
                      <Input name='spouseName' label='Spouse/Partner Name' />
                      <Input name='spouseOccupation' label='His/Her Occupation/ Company' />
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                      className={classes.MobileInputs}
                    >
                      <Input name='hobbies' label='Hobbies and Interests' />
                    </div>
                  </div>
                  <button type='submit' disabled={disabled} className={classes.Submit + ' btn-primary'}>
                    Submit
                  </button>
                  <div className='border' style={{ marginTop: '5.5vw' }}></div>
                  <p className='title-text-small brown' style={{ margin: '2em auto' }}>
                    Subscriptions
                  </p>
                  <div className={classes.MembershipContainer}>
                    <div className={classes.MembershipTag}>
                      <div className={classes.Title}>Membership: {camelToSentence(state.membershipStatus) || 'Inactive'}</div>
                      {state.membershipStatus &&
                      state.membershipStatus.toLowerCase() !== 'inactive' &&
                      subscription &&
                      !subscription.cancel_at_period_end ? (
                        <div className={classes.Button} onClick={() => handleCancelMembership()}>
                          Cancel
                        </div>
                      ) : null}
                    </div>

                    {subscription && subscription?.current_period_end && !subscription?.cancel_at_period_end ? (
                      <div className={classes.Subtitle}>
                        Will Renew At: {moment.unix(subscription.current_period_end).format('YYYY-MM-DD')}
                      </div>
                    ) : null}

                    {subscription && subscription?.cancel_at_period_end && subscription?.current_period_end ? (
                      <div className={classes.Subtitle}>
                        Membership Ends At: {moment.unix(subscription.current_period_end).format('YYYY-MM-DD')}
                      </div>
                    ) : null}

                    {state.membershipStatus !== 'inactive' && !subscription ? (
                      <div className={classes.Subtitle}>Membership Ends At: 31 October 2021</div>
                    ) : (
                      ''
                    )}
                  </div>
                  <div className='border' style={{ marginTop: '5.5vw' }}></div>

                  <ViewCards profilePage detectChange={detectChange} />
                  <div className={classes.ButtonPrimary + ' btn-primary'} onClick={() => setCardsModal(true)}>
                    Change Default Payment
                  </div>
                  <div className='border' style={{ marginTop: '5.5vw' }}></div>
                  <p className='title-text-small brown' style={{ margin: '2em auto' }}>
                    Transactions
                  </p>
                  <TransactionsTable state={state} />
                </Form>
              )}
            </Formik>
          </div>
        </div>
        <div className='events-modal-container'>
          <Modal open={cardsModal} setModalOpen={setCardsModal} classNames={[classes.MyModal]} onClose={() => setCardsModal(false)}>
            <div className={classes.ModalContent}>
              <ChangeDefaultPayment setOpen={setCardsModal} setDetectChange={setDetectChange} />
            </div>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default Profile;
