import classes from './LoginModal.module.css';
import Modal from 'react-responsive-modal';
import React from 'react';
import LoginSmiley from '../../assets/loginSmiley.png';
import { useHistory } from 'react-router-dom';

const LoginModal = ({ open, setOpen, text = '', isLogin }) => {
  const history = useHistory();
  return (
    <div className='login-modal'>
      <Modal open={open} onClose={() => setOpen(false)} classNames={{ root: 'login-modal' }}>
        <div className={classes.LoginModal}>
          <img src={LoginSmiley} alt='' />
          <div className={classes.ModalSubTitle}>
            {isLogin ? '  Please login to your Harvard Club of Silicon Valley profile to access this feature of the site' : text}
          </div>
          {isLogin ? (
            <div onClick={() => history.push('/auth/login')} className={classes.LoginButton + ' btn-primary btn-brown'}>
              Login
            </div>
          ) : null}
        </div>
      </Modal>
    </div>
  );
};

export default LoginModal;
