import classes from "./InfoCard.module.css";
import React from "react";

const InfoCard = ({ image, title, subTitle }) => {
  return (
    <div className={classes.Card}>
      <div className="text-center">
        <img src={image} alt="" />
      </div>
      <p className={classes.CardTitle}>{title}</p>
      <p className={classes.SubTitle}>{subTitle}</p>
    </div>
  );
};

export default InfoCard;
