import classes from './AdvancedLeadership.module.css';
import React from 'react';
import BgWithText from '../../components/BgWithText/BgWithText';
import ALS from '../../assets/ALS.png';
import { ADVANCED_LEADERSHIP_CARDS } from '../../constants';

const AdvancedLeadership = () => {
  return (
    <div>
      <BgWithText title='Advanced Leadership Initiative' image='bg2' />
      <div className={classes.Container}>
        <div className={classes.ContainerHead}>
          <div style={{ flexDirection: 'column', display: 'flex' }}>
            <div className='LargeText'>
              Advanced Leadership Initiative: {window.screen.width >= 600 && <br />}
              <span className='brown'> Call for Nominations </span>
            </div>
            <p>
              As a member of the University’s alumni community, please help Harvard’s
              <span className='brown bold'> Advanced Leadership Initiative </span>
              identify distinguished leaders for the 2022 ALI Fellowship.
            </p>
            <p style={{ marginBlockStart: '0' }}>
              As featured in the{' '}
              <a href='https://www.nytimes.com/2018/04/05/education/learning/retraining-new-career.html' className='brown bold'>
                New York Times{' '}
              </a>
              and the{' '}
              <a
                href='https://news.harvard.edu/gazette/story/newsplus/new-ali-faculty-chair-welcomes-record-breaking-cohort/'
                className='brown bold'
              >
                Harvard Gazette
              </a>{' '}
              , ALI is a year-long educational fellowship for highly accomplished leaders transitioning from their primary careers to take
              on the world’s most pressing problems. ALI Fellows focus on education, public health, gender equality, social justice, climate
              change, and other significant social issues.
            </p>
          </div>
          <div className={classes.ImageContainer}>
            <img className={classes.GraduateImage} src={ALS} alt='' />
          </div>
        </div>
        <div className={classes.CardsContainer}>
          <div className={classes.Title}>Distinctive features of the ALI Fellowship include:</div>
          <div className={classes.Cards}>
            {ADVANCED_LEADERSHIP_CARDS.map((card) => (
              <div className={classes.Card + ' whiteCard'}>
                <div className={classes.CardTitle}>{card.title}</div>
                <div className={classes.CardContent}>{card.content}</div>
                <div className={classes.CardOverlay}></div>
              </div>
            ))}
          </div>
        </div>
        <div className={classes.TextsContainer}>
          <p>
            If you’re interested in pursuing the 2022 ALI Fellowship, beginning in January 2022, or want to nominate a candidate, you can
            read more about the application process{' '}
            <a
              href='https://harvardadvancedleadership.formstack.com/forms/nomination'
              target='_blank'
              rel='noreferrer'
              className='brown underline bold'
            >
              here
            </a>
            .
          </p>
          <p>
            There are three application deadlines on March 31, June 30 and September 30 for the 2022 fellowship. The chances of being
            invited to the Fellowship Cohort are greatest in the earlier selection rounds, as the number of places for Advanced Leadership
            Fellows is limited and the number of inquiries continues to increase through the cycle.
          </p>
          <p>
            If you have any questions about the 2022 ALI Fellowship, you may contact{' '}
            <a href='mailto:advancedleadershipselection@harvard.edu' target='_blank' rel='noreferrer' className='brown underline bold'>
              advancedleadershipselection@harvard.edu
            </a>
            , <br />
            <a href='tel:+16174965479' className='brown underline bold'>
              +1-617-496-5479
            </a>{' '}
            , or visit the ALI site{' '}
            <a href='https://www.advancedleadership.harvard.edu/nominate' target='_blank' rel='noreferrer' className='brown underline bold'>
              Here
            </a>
          </p>
          <p>
            We thank you for helping identify leaders who seek to re-engage with Harvard as they prepare for new achievements driving social
            good.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AdvancedLeadership;
